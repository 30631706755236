<template>
  <div>
    <div class="text-center">
      <h3 class="peeps-features">
        Here are just some of the ways that Peeps can help you keep track of
        your home.
      </h3>
    </div>
    <div class="d-flex flex-wrap justify-space-around">
      <blob-feature
        :active="blob"
        :class="`blob-feature-${index}`"
        @click="showFeatureDetail"
        @hover="showFeatureDetail"
        v-for="(b, index) in blobs"
        :key="index"
        :value="b.feature"
      ></blob-feature>
    </div>
    <div>
      <v-card max-width="400" class="mx-auto" hover flat>
        <div class="image-container-feature">
          <v-img
            class="solar-card"
            src=" /gif/SolarPanelsSunMoving.GIF"
            v-if="blob == 'solar'"
          ></v-img>
          <v-img
            class="septic-card"
            src="@/assets/img/SepticSystem.png"
            v-if="blob == 'septic'"
          ></v-img>
          <v-img
            class="hvac-card"
            src="@/assets/img/hvac.svg"
            v-if="blob == 'hvac'"
          ></v-img>
          <v-img
            class="water-card"
            src="@/assets/img/water.svg"
            v-if="blob == 'water'"
          ></v-img>
          <v-img
            class="tax-card"
            src="@/assets/img/tax.svg"
            v-if="blob == 'tax'"
          ></v-img>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

import BlobFeature from '@/components/Core/BlobFeature.vue'
export default {
  components: {
    BlobFeature
  },
  data() {
    return {
      blobs: [
        { feature: 'water' },
        { feature: 'septic' },
        { feature: 'hvac' },
        { feature: 'solar' },
        { feature: 'tax' }
      ],
      blob: 'water',
      featureInterval: null
    }
  },
  mounted() {
    this.animateFeatures()
    this.setFeatureInterval()
  },
  beforeDestroy() {
    this.clearFeatureInterval()
  },
  methods: {
    showFeatureDetail(blob) {
      this.blob = blob
      this.clearFeatureInterval()
    },

    animateFeatures() {
      for (let i = 0; i < 5; i++) {
        let selector = `.blob-feature-${i}`
        gsap.from(selector, {
          scrollTrigger: {
            trigger: selector,
            start: 'top bottom',
            scrub: 1
          },
          // x: !(i & 1) ? -100 : 100,
          // x: i == 2 ? 0 : i < 3 ? -100 : 100,
          // y: i == 2 ? -50 : 0,
          y: -60,
          duration: 1,
          opacity: 0,
          ease: 'power4'
        })
      }
    },
    changeFeatureBlob() {
      let i
      for (i = 0; i < this.blobs.length; i++) {
        if (this.blob == this.blobs[i].feature) {
          break
        }
      }
      if (i == this.blobs.length - 1) {
        i = 0
      } else {
        i = i + 1
      }
      this.blob = this.blobs[i].feature
    },
    setFeatureInterval() {
      this.featureInterval = setInterval(() => {
        this.changeFeatureBlob()
      }, 3000)
    },
    clearFeatureInterval() {
      clearInterval(this.featureInterval)
    }
  }
}
</script>

<style scoped lang="sass">
.image-container-feature
  max-width: 400px

.peeps-features
  --not-quite-black-rgb: 35, 39, 42
  text-rendering: optimizeLegibility
  -webkit-box-direction: normal
  color: var(--not-quite-black)
  margin: 0
  padding: 0
  border: 0
  outline: 0
  font-weight: inherit
  font-style: inherit
  font-family: inherit
  vertical-align: baseline
  font-size: 1.4rem
  line-height: 1.625
  margin-top: 24px

@media (max-width:450px)
  .peeps-features
    font-size: 1rem
</style>
